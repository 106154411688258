import App from "firebase/app";
import "firebase/auth";
import "firebase/firebase-firestore";
import "firebase/storage";
import { HEADERS } from "./config";

const firebaseConfig = {
  apiKey: "AIzaSyBHr51-luWTlhfrT1XTfnW749OAPM0cEH4",
  authDomain: "shipzzer.firebaseio.com",
  databaseURL: "https://shipzzer.firebaseio.com",
  projectId: "shipzzer",
  storageBucket: "shipzzer.appspot.com",
  messagingSenderId: "674325335279",
};

App.initializeApp(firebaseConfig);
export const auth = App.auth();
export const db = App.firestore();
 export const storage = App.storage();

 export const getHeaders = () =>
   new Promise((resolve, reject) => {
     auth.onAuthStateChanged(function (user) {
       if (user) {
         user
           .getIdToken(true)
           .then((idToken) => resolve({ ...HEADERS, Authorization: idToken }))
           .catch((error) => reject(error));
         // User is signed in.
       } else {
         // No user is signed in.
         console.log("No user is signed in.");
       }
     });
   });

  
export default App;
